@import "../../commons/config";

.react-tabs {
  display: flex;

  flex-direction: column;

  justify-content: center;
  align-items: center;
}

.react-tabs__tab-list {
  list-style: none;
  background-color: darken($mainColor, 2);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100vw;
  margin: 0;
  padding: 10px 0;

  * {
    padding: 5px 15px;
    margin: 0 5px;

    cursor: pointer;

    border-radius: 5px;
    color: #fff;

    transition: background-color 0.2s ease;
    -moz-transition: background-color 0.2s ease;
    -webkit-transition: background-color 0.2s ease;
  }

  *:hover {
    background-color: rgba($accentColor, 0.1);
  }

  .react-tabs__tab--selected {
    background-color: rgba($accentColor, 0.4);
  }
}
