.loadingScreen {
  background-color: rgb(12, 29, 39);

  position: fixed;

  top: 0;
  left: 0;

  z-index: 90;

  width: 100vw;
  height: 100vh;

  display: flex;

  flex-wrap: wrap;
  flex-direction: row;

  justify-content: center;
  align-items: center;
}

.loadingText {
  color: #fff;
  font-size: 36px;

  font-weight: 600;
}
