.content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-items: center;
  justify-content: center;

  color: #fff;
}

%side {
  position: relative;
  min-height: 300px;
}

.right {
  @extend %side;

  width: 30%;

  padding: 80px 0;

  max-height: 100vh;

  overflow-y: scroll;

  text-align: center;
}

.left {
  @extend %side;

  width: 70%;
  min-width: 330px;
  color: #000;
}

form {
  text-align: right;
  width: 90%;

  max-width: 400px;

  margin: auto;

  label {
    display: block;
    font-weight: 500;
    opacity: 0.8;
    margin: 30px 0 0;

    &:hover {
      opacity: 1;
    }
  }

  img {
    width: 100%;
    height: 200px;
    object-fit: contain;
    margin: 10px 0;
  }

  %inputStyle {
    color: #fff;
    width: 100%;

    border: none;

    padding: 0 10px;

    background-color: rgb(35, 54, 64);

    border-radius: 7px;
  }

  input {
    @extend %inputStyle;

    height: 30px;
  }

  select {
    @extend %inputStyle;

    height: 30px;
  }

  textarea {
    @extend %inputStyle;

    padding: 10px;
    height: 150px;
  }

  //Centering Some Labels
  $labelsIds: ("lat", "lng", "date", "time");

  @each $id in $labelsIds {
    label[for="#{$id}"] {
      text-align: center;
      font-size: 12px;
      margin: 10px 0 0;
    }
  }
}

.double {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-items: center;
  justify-content: center;

  .side {
    width: calc(50% - 10px);

    &:first-child {
      margin-left: 10px;
    }
    &:last-child {
      margin-right: 10px;
    }
  }
}

.sectionTitle {
  text-align: center !important;
  margin: 36px 0 0;
  font-size: 24px;
  opacity: 0.5;
}

.delete {
  color: #fff;
  background: none;
  border: 1px solid rgb(70, 27, 27);
  border-radius: 7px;
  background-color: rgb(107, 38, 38);
  width: 100%;
  padding: 5px 0;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;

  z-index: 20;

  width: 100vw;
  height: 100vh;
  background-color: rgb(12, 29, 39);

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;
}

.loadingText {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
}
.modeToggleContainer {
  position: fixed;

  transform: translate(-50%, 0);

  z-index: 15;

  background-color: rgba(12, 29, 39, 0.5);

  color: #fff;

  border-radius: 50px;

  left: 50%;
  bottom: 20px;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  overflow: hidden;
}

.option {
  cursor: pointer;

  width: 135px;
  padding: 10px 20px;
  border-right: 0.5px solid rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;
  -moz-transition: background-color 0.3s ease;
  -webkit-transition: background-color 0.3s ease;

  &.active {
    background-color: rgb(12, 29, 39);
    color: #fff;

    &:hover {
      background-color: rgb(12, 29, 39);
    }
  }

  &:first-child {
    border: none;
  }

  &:hover {
    background-color: rgba(12, 29, 39, 0.2);
  }
}

.clearButton {
  position: absolute;
  z-index: 16;
  top: 20px;
  left: 20px;

  background-color: #fff;
  border: none;
  padding: 5px 10px;

  border-radius: 7px;

  font-weight: 600;
}
.undoButton {
  position: absolute;
  z-index: 16;
  top: 20px;
  left: 110px;

  background-color: #fff;
  border: none;
  padding: 5px 10px;

  border-radius: 7px;

  font-weight: 600;
}

.saveBtn {
  cursor: pointer;
  background: #69984b;
  border: none;
  padding: 5px 10px;
  border-radius: 30px;
  width: 100%;
  margin: 30px 0 0;
  font-size: 17px;
  font-weight: 700;
  color: #fff;
}
.deleteBtn {
  cursor: pointer;
  background: #6e2121;
  border: none;
  padding: 5px 10px;
  border-radius: 30px;
  width: 100%;
  margin: 30px 0 0;
  font-size: 17px;
  font-weight: 700;
  color: #fff;
}

@media (max-width: 1100px) {
  .right,
  .left {
    width: 100%;
  }

  .right {
    max-height: unset;
  }
}
