.blurredElement {

    /* Any browser which supports CSS3 */
   filter: blur(3px);


   /* Webkit in Chrome 52, Safari 9, Opera 39, and earlier */
   -webkit-filter: blur(3px);
}

.dimDown{
    position: fixed;
    top: 0;
    z-index: 30;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.2);

}