.body {
  overflow: hidden;
}
.windowBg {
  z-index: 90;

  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  display: flex;

  flex-direction: column;

  align-items: center;
  justify-content: center;
}
.locationWindow {
  max-width: calc(80vw - 60px);
  min-width: calc(60vw - 60px);
  max-height: calc(90vh - 40px);

  position: relative;

  background-color: rgba(6, 19, 27, 0.8);
  border-radius: 10px;

  overflow: hidden;
  overflow-y: auto;

  direction: ltr;

  button {
    position: fixed;

    margin: 10px;

    background-color: rgba(126, 35, 35, 0.8);
    color: #fff;
    border: none;

    padding: 5px 10px;

    border-radius: 30px;
  }
  img {
    width: 100%;
    max-height: 360px;
    object-fit: contain;
    background: rgba(0, 0, 0, 0.5);
  }
}

.text {
  direction: rtl;
  padding: 20px 30px;
  .title,
  .content {
    color: #fff;
  }

  .title {
    font-size: 24px;
    font-weight: 600;
  }

  .content p {
    margin: 10px 0;
  }
}

@media (max-width: 900px) {
  .locationWindow {
    max-width: unset;
    max-height: 80vh;
    width: 95vw;
  }
}
