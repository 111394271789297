@import "../../../../commons/config";

.user {
  display: flex;
  flex-direction: row;

  cursor: pointer;

  justify-content: space-evenly;
  align-items: center;

  background-color: #fff;
  color: lighten($mainColor, 3);

  border-radius: 7px;

  margin: 2px 0;

  width: 100%;
  * {
    margin: 10px;
  }
}

.userForm {
  background-color: #fff;
  color: lighten($mainColor, 3);

  position: relative;

  width: 100%;

  border-radius: 7px;

  text-align: center;

  padding: 20px 0;
  margin: 2px 0;

  overflow: hidden;

  animation: 0.4s ease both form;

  h1 {
    font-size: 20px;
    margin: 0;
  }

  * {
    animation: 0.4s ease both fade-right;
    position: relative;
  }

  input,
  select {
    background-color: #fff;
    color: lighten($mainColor, 3);
    border: 1px solid lighten($mainColor, 3);
  }

  .save {
    animation: 0.4s ease both fade;
    position: absolute;
    bottom: 20px;
    left: 20px;

    border: 1px solid #2f9256;
    border-radius: 6px;
    color: #fff;
    padding: 5px 10px;
    background-color: #34945a;

    &:disabled {
      background-color: #45554b;
      border: 1px solid #45554b;
    }
  }
  .delete {
    animation: 0.4s ease both fade;
    position: absolute;
    bottom: 20px;
    left: 90px;

    border: 1px solid #922f2f;
    border-radius: 6px;
    color: #fff;
    padding: 5px 10px;
    background-color: #943434;

    &:disabled {
      background-color: #45554b;
      border: 1px solid #45554b;
    }
  }
}

@keyframes form {
  0% {
    height: 0px;
  }

  100% {
    height: 380px;
  }
}

@keyframes fade-right {
  0% {
    left: 50px;
    opacity: 0;
  }

  100% {
    left: 0px;
    opacity: 1;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
