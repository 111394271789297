@import "../config";

.header {
  width: 100vw;
  padding: 20px 0;

  position: relative;

  background-color: darken($mainColor, 2);

  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  img {
    height: 80px;
    margin: 0 5px;
  }

  .title {
    color: #fff;
    font-size: 36px;
    font-weight: 900;
    margin: 0 5px;
  }

  a {
    text-decoration: none;
  }
}

.accountBox {
  height: 50px;
  position: absolute;
  right: 20px;

  display: flex;

  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0 10px;
  border-radius: 30px;

  cursor: pointer;

  transition: background-color 0.4s ease;
  -moz-transition: background-color 0.4s ease;
  -webkit-transition: background-color 0.4s ease;

  .circle {
    background-color: $accentColor;
    padding: 5px 10px;
    border-radius: 30px;

    color: rgb(34, 34, 34);

    cursor: pointer;

    transition: background-color 0.4s ease;
    -moz-transition: background-color 0.4s ease;
    -webkit-transition: background-color 0.4s ease;
  }

  .name {
    margin: 0 5px;
    font-weight: 600;
    color: #fff;
  }

  .logOut button {
    padding: 5px 10px;

    background-color: rgb(83, 34, 34);
    color: #fff;

    border: none;

    opacity: 0;

    border-radius: 20px;

    margin: 0 10px 0 0;

    cursor: pointer;

    transition: opacity 0.4s ease;
    -moz-transition: opacity 0.4s ease;
    -webkit-transition: opacity 0.4s ease;
  }
  &:hover .logOut > button {
    opacity: 1;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }

  &:hover .circle {
    background-color: darken($accentColor, 10);
  }
}

@media (max-width: 700px) {
  .header {
    flex-direction: column;
  }
  .accountBox {
    position: relative;
    right: 35px;

    margin: 10px 0;

    transition: right 0.4s ease;
    -moz-transition: right 0.4s ease;
    -webkit-transition: right 0.4s ease;

    &:hover {
      right: 0;
    }
  }
}
