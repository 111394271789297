@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100;300;400;500;700;800;900&display=swap");
@import "./commons/config";

html,
body {
  margin: 0;
  direction: rtl;
  background-color: rgb(12, 29, 39);
}

* {
  box-sizing: border-box;
  font-family: "Heebo", sans-serif;
  outline: none;
}

button {
  cursor: pointer;
}
.dialog-area {
  position: fixed;
  top: 0;
  z-index: 90;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dialog {
  text-align: center;
  background: #fff;
  padding: 10px 40px 30px;
  min-width: 340px;
  min-width: 80vw;
  border-radius: 7px;
}
.title {
  font-size: 20px;
  font-weight: 700;
  margin: 10px 0;
}
.dialog-area .yes {
  border: none;
  padding: 5px 20px;
  background: #357750;
  color: #fff;
  border-radius: 7px;
  margin: 15px 5px 0;
}
.dialog-area .yes:hover {
  background: #255a3b;
}
.dialog-area .no {
  border: none;
  padding: 5px 20px;
  background: #773535;
  color: #fff;
  border-radius: 7px;
  margin: 15px 5px 0;
}
.dialog-area .no:hover {
  background: #5a2525;
}
.loading {
  color: #fff;
  font-size: 20px;
  padding: 20px 0;
  animation: 2s ease loading infinite;
}
.nores {
  color: #fff;
  font-size: 20px;
  padding: 20px 0;
}
/* width */
*::-webkit-scrollbar {
  width: 10px;
}

/* Track */
*::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 30px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: rgb(219, 219, 219);
}
@keyframes loading {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
