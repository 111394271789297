.loginFormContainer{
    position: relative;

    
    background-color: rgb(12, 29, 39);
    
    min-width: 330px;
    max-width: 80vw;
    
    padding: 20px 10px;
    
    border-radius: 7px;
    
    color: #fff;

    overflow: hidden;
    
    
    -webkit-box-shadow: 0px 0px 59px 0px rgba(0,0,0,0.37);
    -moz-box-shadow: 0px 0px 59px 0px rgba(0,0,0,0.37);
    box-shadow: 0px 0px 59px 0px rgba(0,0,0,0.37);
    
    &.login{
      animation: 0.6s cubic-bezier(0.31, -0.01, 0.15, 1.01) both login;
    }
    &.register{
      animation: 0.6s cubic-bezier(0.31, -0.01, 0.15, 1.01) both register;
    }

    form{
      animation: 0.6s cubic-bezier(0.31, -0.01, 0.15, 1.01) both fade-in;
    }

    a{
        color: #fff;
        text-decoration: none;
        font-size: 16px;
        font-weight: 400;
        margin: 20px 0;
    }
    
     button{
        width: 100%;
        margin: 20px 0;
        border: none;
    
        padding: 5px 0;
    
        border-radius: 7px;
    }
    
     h1{
        margin: 0;
    
        text-align: center;
    }
}


.errors{
    color: tomato;
    text-align: center;
    margin: 10px 0;
}

@keyframes login {
    0% {
      height: 527px;
    }
    
    100% {
      height: 360px;
    }
  }
@keyframes register {
    0% {
      height: 360px;
    }
    
    100% {
      height: 527px;
    }
  }

  @keyframes fade-in{
    0%{
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
  }