.center {
  position: fixed;
  top: 0;
  z-index: 1;

  display: flex;

  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100vw;

  height: 100vh;
}

.cant-access {
  color: #fff;
  text-align: center;

  h1 {
    margin: 0;
  }

  button {
    background-color: rgba(126, 35, 35, 0.8);
    color: #fff;
    border: none;

    padding: 5px 10px;
    margin: 10px 0;

    border-radius: 30px;
  }
}
