.header{
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;

    display: flex;

    flex-wrap: wrap;
    flex-direction: row;

    align-items: center;
    justify-content: center;
    
    height: 70px;

    text-align: center;

    background: #0C1D27;
    background: linear-gradient(180deg, #0C1D27 0%, rgba(9, 38, 52, 0) 100%)

}

.header button{
    color: #fff;
    position: relative;
    margin: 10px;
    background: none;
    border: none;
    outline: none;

    font-weight: 600;
    font-size: 16px;

    cursor: pointer;
}

