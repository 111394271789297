.oops{
    display: flex;
    flex-wrap: wrap;

    flex-direction: column;

    justify-content: center;
    align-items: center;

    text-align: center;

    width: 100vw;
    height: 100vh;

    color: #fff;
}

.oops h1{
    display: block;
    margin: 0;
}
.oops p{
    display: block;
    margin: 0;
}