.item {
  background-color: whitesmoke;

  border-radius: 2px;

  overflow: hidden;

  margin: 3px;

  width: 312px;

  max-height: 350px;

  -webkit-box-shadow: 0px 0px 59px 0px rgba(0, 0, 0, 0.37);
  -moz-box-shadow: 0px 0px 59px 0px rgba(0, 0, 0, 0.37);
  box-shadow: 0px 0px 59px 0px rgba(0, 0, 0, 0.37);

  transition: transform 0.3s ease, border 0.3s ease;
  -moz-transition: transform 0.3s ease, border 0.3s ease;
  -webkit-transition: transform 0.3s ease, border 0.3s ease;

  cursor: pointer;

  &:hover {
    transform: scale(1.02);
    border: 1px solid rgb(161, 161, 161);
  }

  .details {
    text-align: right;
    padding: 10px 20px;
    cursor: pointer;
  }

  .title {
    font-size: 20px;

    font-weight: 600;
    cursor: pointer;
  }

  img {
    height: 160px;
    object-fit: cover;
  }

  .content {
    max-height: 130px;

    overflow: hidden;

    p {
      margin: 0;
    }
  }

  .expires {
    font-size: 11px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 400;
    text-align: left;

    margin: 20px 0 0;
    cursor: pointer;

    &:before {
      font-weight: 600;
      content: "בתוקף עד: ";
    }
  }
}
