@import '../../commons/config';

.locationsList{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: center;
    align-items: start;
}

.newLocationButton{
    background-color: $accentColor;
    position: fixed;
    z-index: 40;
    bottom: 20px;
    left: 20px;

    color: darken($accentColor, 35);

    border: none;

    font-size: 24px;

    font-weight: 300;

    padding: 5px 15px;

    border-radius: 30px;

    text-decoration: none;
}