@import "../../../commons/config";

.usersList {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90vw;

  padding: 10px 0;
}
